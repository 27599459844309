@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
html,
body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 20px;
    min-width: 320px;
    color: var(--darkText);
    background-color: var(--bodyBg);
    -ms-overflow-style: scrollbar;
}
::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}
::-webkit-scrollbar-thumb {
    border: 0 !important;
    background-color: #b2b2b2 !important;
    border-radius: 12px !important;
}
.loader-wrapper{ position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 9999; background: rgba(0,0,0,0.4); display: flex; align-items: center; justify-content: center; }

ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
.hover:hover {
    cursor: pointer;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
ul {
    list-style-type: none;
}
* {
    outline: none !important;
}

.main-content {
    transition: all 0.3s ease-in-out;
}
.backGround-top {
    background-image: url("../images/Mr_bean_.png");
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    opacity: 0.5;
    z-index: 0;
    pointer-events: none;
}
.backGround-bottom {
    background-image: url("../images/mr_bean_1.png");
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    opacity: 0.5;
    z-index: 0;
    pointer-events: none;
}
.backGround-header {
    background-image: url("../images/mr_bean_dhi.png");
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    opacity: 0.5;
    z-index: 0;
    pointer-events: none;
}
.backGround-header {
    background-size: 150px; 
    width: 200px;
    height: 210px;
    /* transform: rotate(45deg); */
    position: fixed;
    left: 15%;
    top: -5%;
}
.backGround-top {
    background-size: 150px; 
    width: 200px;
    height: 210px;
    /* transform: rotate(45deg); */
    position: fixed;
    left: 70%;
    top: -1%;
}
.backGround-bottom{
    background-size: 230px; 
    width: 200px;
    height: 210px;
    /* transform: rotate(45deg); */
    position: fixed;
    left: 0%;
    top: 75%;
}

.diff-viewer table td pre{
    white-space: pre-wrap;
    word-break: break-all;
    overflow-wrap: break-word;
}
.fw-700{font-weight: 700 !important;}
.MuiTypography-root{font-family:Poppins,sans-serif !important;}
.MuiChip-label{font-weight: 700;}

@keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideInOut {
    0% {
      transform: translate(0, 80px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
.fade-animation{
    animation: fadeInOut 600ms ease-in-out, slideInOut 600ms ease-in-out;
}

@media (min-width: 600px) {
    .main-content {
        padding: 100px 30px 20px 30px;
    }
}
@media (max-width: 599.98px) {
    .main-content {
        padding: 76px 10px 20px;
    }
    .backGround-top{
        width: 175px;
        height: 210px;
        left: 50%;
        top: -5%;
    }
}

@media (min-width: 900px) {
    .main-content {
        padding: 115px 33px 40px 33px;
    }
}
@media (min-width: 1200px) {
    .main-content {
        padding: 115px 33px 40px 33px;
    }
}